import React, { Component } from "react";
import Helmet from "react-helmet";
import { Grid, Image } from "semantic-ui-react";

import logo from "src/images/STHALA.png";

import "./theme.scss";

class PageNotFound extends Component {
  render() {
    return (
      <div className="page-not-found">
        <Helmet>
          <title>Sthala | Showcase Real Estate</title>
          <meta name="title" content="Sthala | Showcase Real Estate" />
          <meta
            name="description"
            content="Sthala is a showcase for real-estate properties."
          />
        </Helmet>
        <Grid>
          <Grid.Row centered className="banner">
            <Grid.Column computer={8} mobile={16}>
              <p className="header">
                We couldn't find the page you're looking for
              </p>
              <Image src={logo} style={{ height: "3.3rem", margin: "auto" }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default PageNotFound;
